export const urbanEvent = [
  { "start": 1, "end": 2, "entry": { "event": "Wedding" } },
  {
    "start": 3,
    "end": 4,
    "entry": { "event": "Name-giving day celebrations" },
  },
  { "start": 5, "end": 6, "entry": { "event": "Public holiday" } },
  { "start": 7, "end": 8, "entry": { "event": "Procession" } },
  { "start": 9, "end": 10, "entry": { "event": "Harvest Festival" } },
  {
    "start": 11,
    "end": 12,
    "entry": { "event": "Festival celebrating a god" },
  },
  {
    "start": 13,
    "end": 14,
    "entry": { "event": "Celebration of a hero’s return" },
  },
  { "start": 15, "end": 16, "entry": { "event": "Market day" } },
  { "start": 17, "end": 18, "entry": { "event": "Religious rally" } },
  { "start": 19, "end": 20, "entry": { "event": "Official proclamation" } },
  { "start": 21, "end": 22, "entry": { "event": "Amnesty" } },
  {
    "start": 23,
    "end": 24,
    "entry": { "event": "Sudden storm / gale force winds" },
  },
  {
    "start": 25,
    "end": 26,
    "entry": { "event": "Election / Change of civic leader" },
  },
  { "start": 27, "end": 28, "entry": { "event": "Yearly local festival" } },
  { "start": 29, "end": 30, "entry": { "event": "Assassination" } },
  { "start": 31, "end": 32, "entry": { "event": "Performance of a play" } },
  { "start": 33, "end": 34, "entry": { "event": "Musical concert" } },
  { "start": 35, "end": 36, "entry": { "event": "Buskers performance" } },
  { "start": 37, "end": 38, "entry": { "event": "Orator / storyteller" } },
  { "start": 39, "end": 40, "entry": { "event": "Alefest" } },
  { "start": 41, "end": 42, "entry": { "event": "Warrior exhibition" } },
  { "start": 43, "end": 44, "entry": { "event": "Procession of royals" } },
  { "start": 45, "end": 46, "entry": { "event": "Museum exhibition" } },
  { "start": 47, "end": 48, "entry": { "event": "New building being opened" } },
  {
    "start": 49,
    "end": 50,
    "entry": { "event": "Large army passing through town" },
  },
  { "start": 51, "end": 52, "entry": { "event": "Gridlocked traffic" } },
  { "start": 53, "end": 54, "entry": { "event": "Monster invasion!" } },
  { "start": 55, "end": 56, "entry": { "event": "Conference of Wizards" } },
  { "start": 57, "end": 58, "entry": { "event": "Graduation ceremony" } },
  { "start": 59, "end": 60, "entry": { "event": "Traffic accident" } },
  { "start": 61, "end": 62, "entry": { "event": "Freakish weather event" } },
  { "start": 63, "end": 64, "entry": { "event": "Fire in Building" } },
  {
    "start": 65,
    "end": 66,
    "entry": { "event": "Notable local figure assassinated" },
  },
  { "start": 67, "end": 68, "entry": { "event": "Public duel" } },
  { "start": 69, "end": 70, "entry": { "event": "Large scale fire" } },
  { "start": 71, "end": 72, "entry": { "event": "Sinkhole opens" } },
  {
    "start": 73,
    "end": 74,
    "entry": { "event": "Local army returning after recent skirmish" },
  },
  { "start": 75, "end": 76, "entry": { "event": "Co-ordinated attack" } },
  { "start": 77, "end": 78, "entry": { "event": "Protest" } },
  { "start": 79, "end": 80, "entry": { "event": "Flood" } },
  {
    "start": 81,
    "end": 82,
    "entry": { "event": "Martial law enforced (Reason?)" },
  },
  { "start": 83, "end": 84, "entry": { "event": "Wild magic eruption" } },
  { "start": 85, "end": 86, "entry": { "event": "Military conscription" } },
  {
    "start": 87,
    "end": 88,
    "entry": { "event": "Revolution taking place (scale uncertain)" },
  },
  { "start": 89, "end": 90, "entry": { "event": "Public Execution" } },
  { "start": 91, "end": 92, "entry": { "event": "Storm" } },
  { "start": 93, "end": 94, "entry": { "event": "Plague" } },
  { "start": 95, "end": 96, "entry": { "event": "Earthquake" } },
  { "start": 97, "end": 98, "entry": { "event": "Dimensional disruption" } },
  { "start": 99, "end": 100, "entry": { "event": "Invading force" } },
];

export const wildernessEvent = [
  { "start": 1, "end": 2, "entry": { "event": "Landslide / Avalanche" } },
  { "start": 3, "end": 4, "entry": { "event": "Earthquake" } },
  { "start": 5, "end": 6, "entry": { "event": "Storm" } },
  { "start": 7, "end": 8, "entry": { "event": "Druid gathering" } },
  { "start": 9, "end": 10, "entry": { "event": "Religious pilgrimage" } },
  { "start": 11, "end": 12, "entry": { "event": "Army encamped" } },
  {
    "start": 13,
    "end": 14,
    "entry": { "event": "Sudden storm / gale force winds" },
  },
  { "start": 15, "end": 16, "entry": { "event": "Overturned cart" } },
  { "start": 17, "end": 18, "entry": { "event": "Flash flood" } },
  { "start": 19, "end": 20, "entry": { "event": "Forest Fire" } },
  {
    "start": 21,
    "end": 22,
    "entry": { "event": "Local humanoids having a festival" },
  },
  { "start": 23, "end": 24, "entry": { "event": "Solar eclipse" } },
  { "start": 25, "end": 26, "entry": { "event": "Hail (large hailstones?)" } },
  { "start": 27, "end": 28, "entry": { "event": "Hurricane" } },
  { "start": 29, "end": 30, "entry": { "event": "Tribal encounter" } },
  { "start": 31, "end": 32, "entry": { "event": "Animal stampede" } },
  { "start": 33, "end": 34, "entry": { "event": "Tree falls" } },
  { "start": 35, "end": 36, "entry": { "event": "Boulder falls" } },
  { "start": 37, "end": 38, "entry": { "event": "Unseasonable weather" } },
  { "start": 39, "end": 40, "entry": { "event": "Lightning strikes" } },
  { "start": 41, "end": 42, "entry": { "event": "Market" } },
  { "start": 43, "end": 44, "entry": { "event": "Hunting Party" } },
  { "start": 45, "end": 46, "entry": { "event": "Farmers harvesting crops" } },
  { "start": 47, "end": 48, "entry": { "event": "Cart passing" } },
  { "start": 49, "end": 50, "entry": { "event": "Refugees" } },
  { "start": 51, "end": 52, "entry": { "event": "Massed Animals" } },
  { "start": 53, "end": 54, "entry": { "event": "Toll demanded" } },
  { "start": 55, "end": 56, "entry": { "event": "Distressed NPC" } },
  { "start": 57, "end": 58, "entry": { "event": "Besieged settlement" } },
  { "start": 59, "end": 60, "entry": { "event": "Come across structure" } },
  { "start": 61, "end": 62, "entry": { "event": "Battle in progress" } },
  { "start": 63, "end": 64, "entry": { "event": "Beast / Monster carcass" } },
  { "start": 65, "end": 66, "entry": { "event": "Monster - easy encounter" } },
  { "start": 67, "end": 68, "entry": { "event": "Monster - easy encounter" } },
  {
    "start": 69,
    "end": 70,
    "entry": { "event": "Monster - medium encounter" },
  },
  {
    "start": 71,
    "end": 72,
    "entry": { "event": "Monster - medium encounter" },
  },
  { "start": 73, "end": 74, "entry": { "event": "Monster - hard encounter" } },
  {
    "start": 75,
    "end": 76,
    "entry": { "event": "Monster - deadly encounter" },
  },
  { "start": 77, "end": 78, "entry": { "event": "Volcanic eruption" } },
  { "start": 79, "end": 80, "entry": { "event": "Plague (insect / vermin)" } },
  { "start": 81, "end": 82, "entry": { "event": "Interdimensional Rift" } },
  { "start": 83, "end": 84, "entry": { "event": "Army going to war" } },
  { "start": 85, "end": 86, "entry": { "event": "Sinkhole Appears" } },
  {
    "start": 87,
    "end": 88,
    "entry": { "event": "Appearance of Tyrant in the area" },
  },
  { "start": 89, "end": 90, "entry": { "event": "Savage raiding party" } },
  {
    "start": 91,
    "end": 92,
    "entry": { "event": "Marauding monster attacking village" },
  },
  { "start": 93, "end": 94, "entry": { "event": "Undead awakening" } },
  { "start": 95, "end": 100, "entry": { "event": "Unmarked Settlement" } },
];
