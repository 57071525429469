export const quest = [{
  "start": 1,
  "end": 4,
  "entry": { "problem": "Unjust killing", "quest": "Revenge" },
}, {
  "start": 5,
  "end": 8,
  "entry": {
    "problem": "Crime committed, criminal disappeared",
    "quest": "Apprehend / Bounty Hunt",
  },
}, {
  "start": 9,
  "end": 12,
  "entry": { "problem": "Item lost", "quest": "Retrieval" },
}, {
  "start": 13,
  "end": 16,
  "entry": {
    "problem":
      "Tyrant(s) threaten town / business / population (monster / humanoid)",
    "quest": "Repel / Sabotage / Deceive / Sabotage / Extort / Entrap",
  },
}, {
  "start": 17,
  "end": 20,
  "entry": {
    "problem":
      "Environmental disturbance / Strange Weather / Infestation / Plague",
    "quest": "Investigate / Solve",
  },
}, {
  "start": 21,
  "end": 24,
  "entry": {
    "problem": "Magical chaos / Curse",
    "quest": "Investigate / Lift curse / destroy magic",
  },
}, {
  "start": 25,
  "end": 28,
  "entry": {
    "problem": "Kidnapping / Hostage situation",
    "quest": "Rescue / Recover",
  },
}, {
  "start": 29,
  "end": 32,
  "entry": {
    "problem": "Adventurer left some time ago, but is lost",
    "quest": "Rescue",
  },
}, {
  "start": 33,
  "end": 36,
  "entry": {
    "problem": "Something is rumoured, but undiscovered",
    "quest": "Explore / Discover (Artefact, legendary place)",
  },
}, {
  "start": 37,
  "end": 40,
  "entry": {
    "problem": "Riches rumoured within secure, guarded place",
    "quest": "Loot",
  },
}, {
  "start": 41,
  "end": 44,
  "entry": { "problem": "Invasion", "quest": "Repel / Divert" },
}, {
  "start": 45,
  "end": 48,
  "entry": {
    "problem": "Person suspected of something",
    "quest": "Spy / Gather information",
  },
}, {
  "start": 49,
  "end": 52,
  "entry": {
    "problem": "Important item / message / person needs transporting",
    "quest": "Transport / smuggle / escort",
  },
}, {
  "start": 53,
  "end": 56,
  "entry": {
    "problem": "Nefarious / dangerous item being transported",
    "quest": "Waylay / Hijack",
  },
}, {
  "start": 57,
  "end": 60,
  "entry": {
    "problem": "Dangerous item exposed / up for grabs",
    "quest": "Find / Destroy",
  },
}, {
  "start": 61,
  "end": 64,
  "entry": {
    "problem": "Corrupt noble",
    "quest": "Investigate / Implicate / Incriminate / Expose",
  },
}, {
  "start": 65,
  "end": 68,
  "entry": {
    "problem": "Commodity shortage (goods, workforce)",
    "quest": "Transport supplies",
  },
}, {
  "start": 69,
  "end": 72,
  "entry": {
    "problem": "Mystery unresolved",
    "quest": "Research / Explore / Decode",
  },
}, {
  "start": 73,
  "end": 76,
  "entry": {
    "problem":
      "Adventurer thrown into life-threatening situation (environmental or otherwise)",
    "quest": "Survive",
  },
}, {
  "start": 77,
  "end": 80,
  "entry": {
    "problem":
      "Worthy creature’s life threatened (humanoid / animal / monster)",
    "quest": "Protect, eliminate threat",
  },
}, {
  "start": 81,
  "end": 84,
  "entry": {
    "problem": "Execution of innocent party planned",
    "quest": "Prevent",
  },
}, {
  "start": 85,
  "end": 88,
  "entry": {
    "problem":
      "Siege (anything from a small party of goblins surrounding a farmhouse to an army of Imperia Blades surrounding a city!)",
    "quest": "Break",
  },
}, {
  "start": 89,
  "end": 92,
  "entry": {
    "problem": "Huge battle about to erupt",
    "quest": "Negotiate / Prepare / Mediate / Participate",
  },
}, {
  "start": 93,
  "end": 96,
  "entry": {
    "problem": "Spiritually beneficial place rumoured",
    "quest": "Visit / Gain boon",
  },
}, {
  "start": 97,
  "end": 100,
  "entry": {
    "problem": "Foolish individual committed innocent blunder",
    "quest": "PC hired to conceal evidence / Return item / Placate",
  },
}];

export const questSource = [
  { "start": 1, "end": 3, "entry": "Notice board" },
  { "start": 4, "end": 6, "entry": "NPC" },
  { "start": 7, "end": 9, "entry": "Tavern rumour" },
  { "start": 10, "end": 13, "entry": "Stumble into situation" },
  { "start": 14, "end": 16, "entry": "Guild affiliate" },
  { "start": 17, "end": 19, "entry": "Fellow adventurer" },
  { "start": 20, "end": 22, "entry": "Mysterious scroll with info on it" },
  {
    "start": 23,
    "end": 25,
    "entry":
      "Hidden piece of paper inside a basic reward / purchase a pc makes",
  },
  { "start": 26, "end": 28, "entry": "Treasure map" },
  { "start": 29, "end": 31, "entry": "Found journal - Owner missing" },
  { "start": 32, "end": 34, "entry": "Partial map" },
  {
    "start": 35,
    "end": 37,
    "entry": "Inheritance - be it a place, an item, or a title.",
  },
  { "start": 38, "end": 40, "entry": "Geas or Quest Spell" },
  { "start": 41, "end": 43, "entry": "Unholy visions" },
  { "start": 44, "end": 46, "entry": "A lost map or letter found" },
  { "start": 47, "end": 49, "entry": "Sent on mission by friend or relative" },
  { "start": 50, "end": 52, "entry": "A dream" },
  { "start": 53, "end": 55, "entry": "Local temple gives you the quest" },
  { "start": 56, "end": 58, "entry": "Rumour picked up at guard station" },
  { "start": 59, "end": 61, "entry": "Merchant’s Guild / merchants" },
  { "start": 62, "end": 64, "entry": "Noble family entrusts you" },
  { "start": 65, "end": 67, "entry": "Event sparks a memory" },
  { "start": 68, "end": 70, "entry": "Divine inspiration" },
  { "start": 71, "end": 73, "entry": "Overheard conversation" },
  { "start": 74, "end": 76, "entry": "Passing traveller" },
  {
    "start": 77,
    "end": 79,
    "entry":
      "Entrusted with delivering a parcel. Something compels you to peek inside!",
  },
  {
    "start": 80,
    "end": 83,
    "entry": "Herald makes proclamation in market square",
  },
  {
    "start": 84,
    "end": 87,
    "entry": "Orders from higher command, guild or society (from afar perhaps)",
  },
  { "start": 88, "end": 90, "entry": "Note on dead creature" },
  { "start": 91, "end": 93, "entry": "You awake with a map tattooed on you!" },
  { "start": 94, "end": 97, "entry": "Natural disaster reveals something" },
  {
    "start": 98,
    "end": 100,
    "entry":
      "A friend has died as a result of this situation, you must avenge!",
  },
];
