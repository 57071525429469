export const verb = [
  { "start": 1, "end": 1, "entry": { "verb": "abandon" } },
  { "start": 2, "end": 2, "entry": { "verb": "abolish" } },
  { "start": 3, "end": 3, "entry": { "verb": "abuse" } },
  { "start": 4, "end": 4, "entry": { "verb": "accuse" } },
  { "start": 5, "end": 5, "entry": { "verb": "address" } },
  { "start": 6, "end": 6, "entry": { "verb": "aggravate" } },
  { "start": 7, "end": 7, "entry": { "verb": "agitate" } },
  { "start": 8, "end": 8, "entry": { "verb": "aid" } },
  { "start": 9, "end": 9, "entry": { "verb": "aim" } },
  { "start": 10, "end": 10, "entry": { "verb": "alarm" } },
  { "start": 11, "end": 11, "entry": { "verb": "alert" } },
  { "start": 12, "end": 12, "entry": { "verb": "alter" } },
  { "start": 13, "end": 13, "entry": { "verb": "amaze" } },
  { "start": 14, "end": 14, "entry": { "verb": "ambush" } },
  { "start": 15, "end": 15, "entry": { "verb": "amuse" } },
  { "start": 16, "end": 16, "entry": { "verb": "annihilate" } },
  { "start": 17, "end": 17, "entry": { "verb": "annoy" } },
  { "start": 18, "end": 18, "entry": { "verb": "antagonize" } },
  { "start": 19, "end": 19, "entry": { "verb": "appeal" } },
  { "start": 20, "end": 20, "entry": { "verb": "applaud" } },
  { "start": 21, "end": 21, "entry": { "verb": "apprehend" } },
  { "start": 22, "end": 22, "entry": { "verb": "approach" } },
  { "start": 23, "end": 23, "entry": { "verb": "argue" } },
  { "start": 24, "end": 24, "entry": { "verb": "arise" } },
  { "start": 25, "end": 25, "entry": { "verb": "arouse" } },
  { "start": 26, "end": 26, "entry": { "verb": "arrange" } },
  { "start": 27, "end": 27, "entry": { "verb": "arrest" } },
  { "start": 28, "end": 28, "entry": { "verb": "ask" } },
  { "start": 29, "end": 29, "entry": { "verb": "assassinate" } },
  { "start": 30, "end": 30, "entry": { "verb": "assault" } },
  { "start": 31, "end": 31, "entry": { "verb": "assemble" } },
  { "start": 32, "end": 32, "entry": { "verb": "astonish" } },
  { "start": 33, "end": 33, "entry": { "verb": "attack" } },
  { "start": 34, "end": 34, "entry": { "verb": "attempt" } },
  { "start": 35, "end": 35, "entry": { "verb": "attend" } },
  { "start": 36, "end": 36, "entry": { "verb": "auction" } },
  { "start": 37, "end": 37, "entry": { "verb": "audition" } },
  { "start": 38, "end": 38, "entry": { "verb": "avenge" } },
  { "start": 39, "end": 39, "entry": { "verb": "avert" } },
  { "start": 40, "end": 40, "entry": { "verb": "babble" } },
  { "start": 41, "end": 41, "entry": { "verb": "badmouth" } },
  { "start": 42, "end": 42, "entry": { "verb": "bait" } },
  { "start": 43, "end": 43, "entry": { "verb": "ban" } },
  { "start": 44, "end": 44, "entry": { "verb": "banish" } },
  { "start": 45, "end": 45, "entry": { "verb": "baptize" } },
  { "start": 46, "end": 46, "entry": { "verb": "bargain" } },
  { "start": 47, "end": 47, "entry": { "verb": "bark" } },
  { "start": 48, "end": 48, "entry": { "verb": "barricade" } },
  { "start": 49, "end": 49, "entry": { "verb": "barter" } },
  { "start": 50, "end": 50, "entry": { "verb": "bash" } },
  { "start": 51, "end": 51, "entry": { "verb": "bawl" } },
  { "start": 52, "end": 52, "entry": { "verb": "beautify" } },
  { "start": 53, "end": 53, "entry": { "verb": "beckon" } },
  { "start": 54, "end": 54, "entry": { "verb": "befriend" } },
  { "start": 55, "end": 55, "entry": { "verb": "beg" } },
  { "start": 56, "end": 56, "entry": { "verb": "beguile" } },
  { "start": 57, "end": 57, "entry": { "verb": "belch" } },
  { "start": 58, "end": 58, "entry": { "verb": "belittle" } },
  { "start": 59, "end": 59, "entry": { "verb": "bellow" } },
  { "start": 60, "end": 60, "entry": { "verb": "bemoan" } },
  { "start": 61, "end": 61, "entry": { "verb": "bequeath" } },
  { "start": 62, "end": 62, "entry": { "verb": "berate" } },
  { "start": 63, "end": 63, "entry": { "verb": "besiege" } },
  { "start": 64, "end": 64, "entry": { "verb": "bestow" } },
  { "start": 65, "end": 65, "entry": { "verb": "bet" } },
  { "start": 66, "end": 66, "entry": { "verb": "betray" } },
  { "start": 67, "end": 67, "entry": { "verb": "bewilder" } },
  { "start": 68, "end": 68, "entry": { "verb": "bewitch" } },
  { "start": 69, "end": 69, "entry": { "verb": "bid" } },
  { "start": 70, "end": 70, "entry": { "verb": "bite" } },
  { "start": 71, "end": 71, "entry": { "verb": "blab" } },
  { "start": 72, "end": 72, "entry": { "verb": "blame" } },
  { "start": 73, "end": 73, "entry": { "verb": "blast" } },
  { "start": 74, "end": 74, "entry": { "verb": "bleed" } },
  { "start": 75, "end": 75, "entry": { "verb": "bless" } },
  { "start": 76, "end": 76, "entry": { "verb": "blunder" } },
  { "start": 77, "end": 77, "entry": { "verb": "blurt" } },
  { "start": 78, "end": 78, "entry": { "verb": "boast" } },
  { "start": 79, "end": 79, "entry": { "verb": "bother" } },
  { "start": 80, "end": 80, "entry": { "verb": "bow" } },
  { "start": 81, "end": 81, "entry": { "verb": "brag" } },
  { "start": 82, "end": 82, "entry": { "verb": "break" } },
  { "start": 83, "end": 83, "entry": { "verb": "bribe" } },
  { "start": 84, "end": 84, "entry": { "verb": "broadcast" } },
  { "start": 85, "end": 85, "entry": { "verb": "build" } },
  { "start": 86, "end": 86, "entry": { "verb": "capture" } },
  { "start": 87, "end": 87, "entry": { "verb": "careen" } },
  { "start": 88, "end": 88, "entry": { "verb": "caress" } },
  { "start": 89, "end": 89, "entry": { "verb": "carry" } },
  { "start": 90, "end": 90, "entry": { "verb": "carve" } },
  { "start": 91, "end": 91, "entry": { "verb": "catch" } },
  { "start": 92, "end": 92, "entry": { "verb": "celebrate" } },
  { "start": 93, "end": 93, "entry": { "verb": "challenge" } },
  { "start": 94, "end": 94, "entry": { "verb": "charm" } },
  { "start": 95, "end": 95, "entry": { "verb": "chase" } },
  { "start": 96, "end": 96, "entry": { "verb": "cheat" } },
  { "start": 97, "end": 97, "entry": { "verb": "choke" } },
  { "start": 98, "end": 98, "entry": { "verb": "claim" } },
  { "start": 99, "end": 99, "entry": { "verb": "climb" } },
  { "start": 100, "end": 100, "entry": { "verb": "collaborate" } },
  { "start": 101, "end": 101, "entry": { "verb": "collapse" } },
  { "start": 102, "end": 102, "entry": { "verb": "collide" } },
  { "start": 103, "end": 103, "entry": { "verb": "command" } },
  { "start": 104, "end": 104, "entry": { "verb": "complain" } },
  { "start": 105, "end": 105, "entry": { "verb": "compliment" } },
  { "start": 106, "end": 106, "entry": { "verb": "conceal" } },
  { "start": 107, "end": 107, "entry": { "verb": "concoct" } },
  { "start": 108, "end": 108, "entry": { "verb": "condemn" } },
  { "start": 109, "end": 109, "entry": { "verb": "confiscate" } },
  { "start": 110, "end": 110, "entry": { "verb": "conflict" } },
  { "start": 111, "end": 111, "entry": { "verb": "confront" } },
  { "start": 112, "end": 112, "entry": { "verb": "confuse" } },
  { "start": 113, "end": 113, "entry": { "verb": "congratulate" } },
  { "start": 114, "end": 114, "entry": { "verb": "congregate" } },
  { "start": 115, "end": 115, "entry": { "verb": "conjure" } },
  { "start": 116, "end": 116, "entry": { "verb": "consecrate" } },
  { "start": 117, "end": 117, "entry": { "verb": "consider" } },
  { "start": 118, "end": 118, "entry": { "verb": "construct" } },
  { "start": 119, "end": 119, "entry": { "verb": "contact" } },
  { "start": 120, "end": 120, "entry": { "verb": "contaminate" } },
  { "start": 121, "end": 121, "entry": { "verb": "contest" } },
  { "start": 122, "end": 122, "entry": { "verb": "cook" } },
  { "start": 123, "end": 123, "entry": { "verb": "corner" } },
  { "start": 124, "end": 124, "entry": { "verb": "corrupt" } },
  { "start": 125, "end": 125, "entry": { "verb": "cremate" } },
  { "start": 126, "end": 126, "entry": { "verb": "crowd" } },
  { "start": 127, "end": 127, "entry": { "verb": "crown" } },
  { "start": 128, "end": 128, "entry": { "verb": "customize" } },
  { "start": 129, "end": 129, "entry": { "verb": "damage" } },
  { "start": 130, "end": 130, "entry": { "verb": "dance" } },
  { "start": 131, "end": 131, "entry": { "verb": "dare" } },
  { "start": 132, "end": 132, "entry": { "verb": "dash" } },
  { "start": 133, "end": 133, "entry": { "verb": "dazzle" } },
  { "start": 134, "end": 134, "entry": { "verb": "deal" } },
  { "start": 135, "end": 135, "entry": { "verb": "debate" } },
  { "start": 136, "end": 136, "entry": { "verb": "decay" } },
  { "start": 137, "end": 137, "entry": { "verb": "deceive" } },
  { "start": 138, "end": 138, "entry": { "verb": "declare" } },
  { "start": 139, "end": 139, "entry": { "verb": "decline" } },
  { "start": 140, "end": 140, "entry": { "verb": "decompose" } },
  { "start": 141, "end": 141, "entry": { "verb": "decorate" } },
  { "start": 142, "end": 142, "entry": { "verb": "decree" } },
  { "start": 143, "end": 143, "entry": { "verb": "dedicate" } },
  { "start": 144, "end": 144, "entry": { "verb": "deduce" } },
  { "start": 145, "end": 145, "entry": { "verb": "deface" } },
  { "start": 146, "end": 146, "entry": { "verb": "defeat" } },
  { "start": 147, "end": 147, "entry": { "verb": "defend" } },
  { "start": 148, "end": 148, "entry": { "verb": "defuse" } },
  { "start": 149, "end": 149, "entry": { "verb": "deliver" } },
  { "start": 150, "end": 150, "entry": { "verb": "demand" } },
  { "start": 151, "end": 151, "entry": { "verb": "demolish" } },
  { "start": 152, "end": 152, "entry": { "verb": "denounce" } },
  { "start": 153, "end": 153, "entry": { "verb": "deride" } },
  { "start": 154, "end": 154, "entry": { "verb": "despair" } },
  { "start": 155, "end": 155, "entry": { "verb": "destroy" } },
  { "start": 156, "end": 156, "entry": { "verb": "devour" } },
  { "start": 157, "end": 157, "entry": { "verb": "dig" } },
  { "start": 158, "end": 158, "entry": { "verb": "disappear" } },
  { "start": 159, "end": 159, "entry": { "verb": "disarm" } },
  { "start": 160, "end": 160, "entry": { "verb": "discipline" } },
  { "start": 161, "end": 161, "entry": { "verb": "discover" } },
  { "start": 162, "end": 162, "entry": { "verb": "discriminate" } },
  { "start": 163, "end": 163, "entry": { "verb": "discuss" } },
  { "start": 164, "end": 164, "entry": { "verb": "disgrace" } },
  { "start": 165, "end": 165, "entry": { "verb": "disguise" } },
  { "start": 166, "end": 166, "entry": { "verb": "disgust" } },
  { "start": 167, "end": 167, "entry": { "verb": "dishonor" } },
  { "start": 168, "end": 168, "entry": { "verb": "dismount" } },
  { "start": 169, "end": 169, "entry": { "verb": "dispel" } },
  { "start": 170, "end": 170, "entry": { "verb": "disperse" } },
  { "start": 171, "end": 171, "entry": { "verb": "display" } },
  { "start": 172, "end": 172, "entry": { "verb": "displease" } },
  { "start": 173, "end": 173, "entry": { "verb": "dispute" } },
  { "start": 174, "end": 174, "entry": { "verb": "disrespect" } },
  { "start": 175, "end": 175, "entry": { "verb": "disrupt" } },
  { "start": 176, "end": 176, "entry": { "verb": "distill" } },
  { "start": 177, "end": 177, "entry": { "verb": "distress" } },
  { "start": 178, "end": 178, "entry": { "verb": "disturb" } },
  { "start": 179, "end": 179, "entry": { "verb": "divert" } },
  { "start": 180, "end": 180, "entry": { "verb": "dodge" } },
  { "start": 181, "end": 181, "entry": { "verb": "drag" } },
  { "start": 182, "end": 182, "entry": { "verb": "dress" } },
  { "start": 183, "end": 183, "entry": { "verb": "drink" } },
  { "start": 184, "end": 184, "entry": { "verb": "drop" } },
  { "start": 185, "end": 185, "entry": { "verb": "drown" } },
  { "start": 186, "end": 186, "entry": { "verb": "drum" } },
  { "start": 187, "end": 187, "entry": { "verb": "dump" } },
  { "start": 188, "end": 188, "entry": { "verb": "dupe" } },
  { "start": 189, "end": 189, "entry": { "verb": "duplicate" } },
  { "start": 190, "end": 190, "entry": { "verb": "earn" } },
  { "start": 191, "end": 191, "entry": { "verb": "eat" } },
  { "start": 192, "end": 192, "entry": { "verb": "elude" } },
  { "start": 193, "end": 193, "entry": { "verb": "employ" } },
  { "start": 194, "end": 194, "entry": { "verb": "enchant" } },
  { "start": 195, "end": 195, "entry": { "verb": "end" } },
  { "start": 196, "end": 196, "entry": { "verb": "endanger" } },
  { "start": 197, "end": 197, "entry": { "verb": "endear" } },
  { "start": 198, "end": 198, "entry": { "verb": "endorse" } },
  { "start": 199, "end": 199, "entry": { "verb": "enforce" } },
  { "start": 200, "end": 200, "entry": { "verb": "engage" } },
  { "start": 201, "end": 201, "entry": { "verb": "engineer" } },
  { "start": 202, "end": 202, "entry": { "verb": "enjoy" } },
  { "start": 203, "end": 203, "entry": { "verb": "enlarge" } },
  { "start": 204, "end": 204, "entry": { "verb": "enlighten" } },
  { "start": 205, "end": 205, "entry": { "verb": "enlist" } },
  { "start": 206, "end": 206, "entry": { "verb": "enquire" } },
  { "start": 207, "end": 207, "entry": { "verb": "enrage" } },
  { "start": 208, "end": 208, "entry": { "verb": "enrich" } },
  { "start": 209, "end": 209, "entry": { "verb": "enroll" } },
  { "start": 210, "end": 210, "entry": { "verb": "enshrine" } },
  { "start": 211, "end": 211, "entry": { "verb": "ensnare" } },
  { "start": 212, "end": 212, "entry": { "verb": "entangle" } },
  { "start": 213, "end": 213, "entry": { "verb": "enter" } },
  { "start": 214, "end": 214, "entry": { "verb": "entertain" } },
  { "start": 215, "end": 215, "entry": { "verb": "entice" } },
  { "start": 216, "end": 216, "entry": { "verb": "erect" } },
  { "start": 217, "end": 217, "entry": { "verb": "escape" } },
  { "start": 218, "end": 218, "entry": { "verb": "escort" } },
  { "start": 219, "end": 219, "entry": { "verb": "evacuate" } },
  { "start": 220, "end": 220, "entry": { "verb": "evade" } },
  { "start": 221, "end": 221, "entry": { "verb": "evict" } },
  { "start": 222, "end": 222, "entry": { "verb": "exaggerate" } },
  { "start": 223, "end": 223, "entry": { "verb": "examine" } },
  { "start": 224, "end": 224, "entry": { "verb": "excavate" } },
  { "start": 225, "end": 225, "entry": { "verb": "exchange" } },
  { "start": 226, "end": 226, "entry": { "verb": "exclaim" } },
  { "start": 227, "end": 227, "entry": { "verb": "exclude" } },
  { "start": 228, "end": 228, "entry": { "verb": "execute" } },
  { "start": 229, "end": 229, "entry": { "verb": "exhibit" } },
  { "start": 230, "end": 230, "entry": { "verb": "experiment" } },
  { "start": 231, "end": 231, "entry": { "verb": "explode" } },
  { "start": 232, "end": 232, "entry": { "verb": "expose" } },
  { "start": 233, "end": 233, "entry": { "verb": "faint" } },
  { "start": 234, "end": 234, "entry": { "verb": "fake" } },
  { "start": 235, "end": 235, "entry": { "verb": "fall" } },
  { "start": 236, "end": 236, "entry": { "verb": "falsify" } },
  { "start": 237, "end": 237, "entry": { "verb": "fashion" } },
  { "start": 238, "end": 238, "entry": { "verb": "flaunt" } },
  { "start": 239, "end": 239, "entry": { "verb": "flee" } },
  { "start": 240, "end": 240, "entry": { "verb": "fling" } },
  { "start": 241, "end": 241, "entry": { "verb": "flirt" } },
  { "start": 242, "end": 242, "entry": { "verb": "follow" } },
  { "start": 243, "end": 243, "entry": { "verb": "force" } },
  { "start": 244, "end": 244, "entry": { "verb": "foresee" } },
  { "start": 245, "end": 245, "entry": { "verb": "foretell" } },
  { "start": 246, "end": 246, "entry": { "verb": "forge" } },
  { "start": 247, "end": 247, "entry": { "verb": "forgive" } },
  { "start": 248, "end": 248, "entry": { "verb": "fracture" } },
  { "start": 249, "end": 249, "entry": { "verb": "frame" } },
  { "start": 250, "end": 250, "entry": { "verb": "free" } },
  { "start": 251, "end": 251, "entry": { "verb": "freeze" } },
  { "start": 252, "end": 252, "entry": { "verb": "fret" } },
  { "start": 253, "end": 253, "entry": { "verb": "frighten" } },
  { "start": 254, "end": 254, "entry": { "verb": "frustrate" } },
  { "start": 255, "end": 255, "entry": { "verb": "fumble" } },
  { "start": 256, "end": 256, "entry": { "verb": "fund" } },
  { "start": 257, "end": 257, "entry": { "verb": "fuss" } },
  { "start": 258, "end": 258, "entry": { "verb": "gallop" } },
  { "start": 259, "end": 259, "entry": { "verb": "gamble" } },
  { "start": 260, "end": 260, "entry": { "verb": "gather" } },
  { "start": 261, "end": 261, "entry": { "verb": "gaze" } },
  { "start": 262, "end": 262, "entry": { "verb": "gesture" } },
  { "start": 263, "end": 263, "entry": { "verb": "gift" } },
  { "start": 264, "end": 264, "entry": { "verb": "giggle" } },
  { "start": 265, "end": 265, "entry": { "verb": "give" } },
  { "start": 266, "end": 266, "entry": { "verb": "glare" } },
  { "start": 267, "end": 267, "entry": { "verb": "gleam" } },
  { "start": 268, "end": 268, "entry": { "verb": "glimpse" } },
  { "start": 269, "end": 269, "entry": { "verb": "goad" } },
  { "start": 270, "end": 270, "entry": { "verb": "gob" } },
  { "start": 271, "end": 271, "entry": { "verb": "goggle" } },
  { "start": 272, "end": 272, "entry": { "verb": "gossip" } },
  { "start": 273, "end": 273, "entry": { "verb": "grab" } },
  { "start": 274, "end": 274, "entry": { "verb": "grapple" } },
  { "start": 275, "end": 275, "entry": { "verb": "grieve" } },
  { "start": 276, "end": 276, "entry": { "verb": "guard" } },
  { "start": 277, "end": 277, "entry": { "verb": "hamper" } },
  { "start": 278, "end": 278, "entry": { "verb": "harass" } },
  { "start": 279, "end": 279, "entry": { "verb": "harm" } },
  { "start": 280, "end": 280, "entry": { "verb": "help" } },
  { "start": 281, "end": 281, "entry": { "verb": "hide" } },
  { "start": 282, "end": 282, "entry": { "verb": "hit" } },
  { "start": 283, "end": 283, "entry": { "verb": "hold" } },
  { "start": 284, "end": 284, "entry": { "verb": "humiliate" } },
  { "start": 285, "end": 285, "entry": { "verb": "hurry" } },
  { "start": 286, "end": 286, "entry": { "verb": "hurtle" } },
  { "start": 287, "end": 287, "entry": { "verb": "ignite" } },
  { "start": 288, "end": 288, "entry": { "verb": "impede" } },
  { "start": 289, "end": 289, "entry": { "verb": "implore" } },
  { "start": 290, "end": 290, "entry": { "verb": "imprison" } },
  { "start": 291, "end": 291, "entry": { "verb": "infect" } },
  { "start": 292, "end": 292, "entry": { "verb": "infest" } },
  { "start": 293, "end": 293, "entry": { "verb": "inflame" } },
  { "start": 294, "end": 294, "entry": { "verb": "inflict" } },
  { "start": 295, "end": 295, "entry": { "verb": "inform" } },
  { "start": 296, "end": 296, "entry": { "verb": "infringe" } },
  { "start": 297, "end": 297, "entry": { "verb": "infuriate" } },
  { "start": 298, "end": 298, "entry": { "verb": "injure" } },
  { "start": 299, "end": 299, "entry": { "verb": "inspect" } },
  { "start": 300, "end": 300, "entry": { "verb": "inspire" } },
  { "start": 301, "end": 301, "entry": { "verb": "instigate" } },
  { "start": 302, "end": 302, "entry": { "verb": "insult" } },
  { "start": 303, "end": 303, "entry": { "verb": "interfere" } },
  { "start": 304, "end": 304, "entry": { "verb": "interrogate" } },
  { "start": 305, "end": 305, "entry": { "verb": "intimidate" } },
  { "start": 306, "end": 306, "entry": { "verb": "intoxicate" } },
  { "start": 307, "end": 307, "entry": { "verb": "investigate" } },
  { "start": 308, "end": 308, "entry": { "verb": "invite" } },
  { "start": 309, "end": 309, "entry": { "verb": "involve" } },
  { "start": 310, "end": 310, "entry": { "verb": "irritate" } },
  { "start": 311, "end": 311, "entry": { "verb": "jaywalk" } },
  { "start": 312, "end": 312, "entry": { "verb": "jeer" } },
  { "start": 313, "end": 313, "entry": { "verb": "joke" } },
  { "start": 314, "end": 314, "entry": { "verb": "jostle" } },
  { "start": 315, "end": 315, "entry": { "verb": "kick" } },
  { "start": 316, "end": 316, "entry": { "verb": "kill" } },
  { "start": 317, "end": 317, "entry": { "verb": "kiss" } },
  { "start": 318, "end": 318, "entry": { "verb": "lament" } },
  { "start": 319, "end": 319, "entry": { "verb": "laugh" } },
  { "start": 320, "end": 320, "entry": { "verb": "leap" } },
  { "start": 321, "end": 321, "entry": { "verb": "lecture" } },
  { "start": 322, "end": 322, "entry": { "verb": "leer" } },
  { "start": 323, "end": 323, "entry": { "verb": "look" } },
  { "start": 324, "end": 324, "entry": { "verb": "loot" } },
  { "start": 325, "end": 325, "entry": { "verb": "lose" } },
  { "start": 326, "end": 326, "entry": { "verb": "love" } },
  { "start": 327, "end": 327, "entry": { "verb": "lunge" } },
  { "start": 328, "end": 328, "entry": { "verb": "lurk" } },
  { "start": 329, "end": 329, "entry": { "verb": "maim" } },
  { "start": 330, "end": 330, "entry": { "verb": "manhandle" } },
  { "start": 331, "end": 331, "entry": { "verb": "march" } },
  { "start": 332, "end": 332, "entry": { "verb": "market" } },
  { "start": 333, "end": 333, "entry": { "verb": "massacre" } },
  { "start": 334, "end": 334, "entry": { "verb": "meddle" } },
  { "start": 335, "end": 335, "entry": { "verb": "mesmerize" } },
  { "start": 336, "end": 336, "entry": { "verb": "mimic" } },
  { "start": 337, "end": 337, "entry": { "verb": "misspell" } },
  { "start": 338, "end": 338, "entry": { "verb": "mob" } },
  { "start": 339, "end": 339, "entry": { "verb": "mock" } },
  { "start": 340, "end": 340, "entry": { "verb": "murder" } },
  { "start": 341, "end": 341, "entry": { "verb": "mutate" } },
  { "start": 342, "end": 342, "entry": { "verb": "mutilate" } },
  { "start": 343, "end": 343, "entry": { "verb": "nag" } },
  { "start": 344, "end": 344, "entry": { "verb": "narrate" } },
  { "start": 345, "end": 345, "entry": { "verb": "near" } },
  { "start": 346, "end": 346, "entry": { "verb": "notify" } },
  { "start": 347, "end": 347, "entry": { "verb": "obscure" } },
  { "start": 348, "end": 348, "entry": { "verb": "observe" } },
  { "start": 349, "end": 349, "entry": { "verb": "obstruct" } },
  { "start": 350, "end": 350, "entry": { "verb": "offend" } },
  { "start": 351, "end": 351, "entry": { "verb": "oppress" } },
  { "start": 352, "end": 352, "entry": { "verb": "order" } },
  { "start": 353, "end": 353, "entry": { "verb": "overhear" } },
  { "start": 354, "end": 354, "entry": { "verb": "overpower" } },
  { "start": 355, "end": 355, "entry": { "verb": "overturn" } },
  { "start": 356, "end": 356, "entry": { "verb": "parade" } },
  { "start": 357, "end": 357, "entry": { "verb": "parley" } },
  { "start": 358, "end": 358, "entry": { "verb": "patrol" } },
  { "start": 359, "end": 359, "entry": { "verb": "pelt" } },
  { "start": 360, "end": 360, "entry": { "verb": "penalize" } },
  { "start": 361, "end": 361, "entry": { "verb": "perform" } },
  { "start": 362, "end": 362, "entry": { "verb": "persecute" } },
  { "start": 363, "end": 363, "entry": { "verb": "persuade" } },
  { "start": 364, "end": 364, "entry": { "verb": "petition" } },
  { "start": 365, "end": 365, "entry": { "verb": "play" } },
  { "start": 366, "end": 366, "entry": { "verb": "plead" } },
  { "start": 367, "end": 367, "entry": { "verb": "plunder" } },
  { "start": 368, "end": 368, "entry": { "verb": "pollute" } },
  { "start": 369, "end": 369, "entry": { "verb": "pounce" } },
  { "start": 370, "end": 370, "entry": { "verb": "practice" } },
  { "start": 371, "end": 371, "entry": { "verb": "praise" } },
  { "start": 372, "end": 372, "entry": { "verb": "preach" } },
  { "start": 373, "end": 373, "entry": { "verb": "proclaim" } },
  { "start": 374, "end": 374, "entry": { "verb": "prohibit" } },
  { "start": 375, "end": 375, "entry": { "verb": "promote" } },
  { "start": 376, "end": 376, "entry": { "verb": "pronounce" } },
  { "start": 377, "end": 377, "entry": { "verb": "prophesize" } },
  { "start": 378, "end": 378, "entry": { "verb": "prosecute" } },
  { "start": 379, "end": 379, "entry": { "verb": "protect" } },
  { "start": 380, "end": 380, "entry": { "verb": "protest" } },
  { "start": 381, "end": 381, "entry": { "verb": "provide" } },
  { "start": 382, "end": 382, "entry": { "verb": "provoke" } },
  { "start": 383, "end": 383, "entry": { "verb": "prowl" } },
  { "start": 384, "end": 384, "entry": { "verb": "pry" } },
  { "start": 385, "end": 385, "entry": { "verb": "punch" } },
  { "start": 386, "end": 386, "entry": { "verb": "punish" } },
  { "start": 387, "end": 387, "entry": { "verb": "purchase" } },
  { "start": 388, "end": 388, "entry": { "verb": "pursue" } },
  { "start": 389, "end": 389, "entry": { "verb": "push" } },
  { "start": 390, "end": 390, "entry": { "verb": "quarrel" } },
  { "start": 391, "end": 391, "entry": { "verb": "query" } },
  { "start": 392, "end": 392, "entry": { "verb": "queue" } },
  { "start": 393, "end": 393, "entry": { "verb": "rage" } },
  { "start": 394, "end": 394, "entry": { "verb": "raid" } },
  { "start": 395, "end": 395, "entry": { "verb": "ransack" } },
  { "start": 396, "end": 396, "entry": { "verb": "rebel" } },
  { "start": 397, "end": 397, "entry": { "verb": "recite" } },
  { "start": 398, "end": 398, "entry": { "verb": "recount" } },
  { "start": 399, "end": 399, "entry": { "verb": "recruit" } },
  { "start": 400, "end": 400, "entry": { "verb": "rejoice" } },
  { "start": 401, "end": 401, "entry": { "verb": "remark" } },
  { "start": 402, "end": 402, "entry": { "verb": "renege" } },
  { "start": 403, "end": 403, "entry": { "verb": "repair" } },
  { "start": 404, "end": 404, "entry": { "verb": "research" } },
  { "start": 405, "end": 405, "entry": { "verb": "rescue" } },
  { "start": 406, "end": 406, "entry": { "verb": "resist" } },
  { "start": 407, "end": 407, "entry": { "verb": "restrain" } },
  { "start": 408, "end": 408, "entry": { "verb": "resurrect" } },
  { "start": 409, "end": 409, "entry": { "verb": "reveal" } },
  { "start": 410, "end": 410, "entry": { "verb": "revolt" } },
  { "start": 411, "end": 411, "entry": { "verb": "reward" } },
  { "start": 412, "end": 412, "entry": { "verb": "ridicule" } },
  { "start": 413, "end": 413, "entry": { "verb": "riot" } },
  { "start": 414, "end": 414, "entry": { "verb": "rob" } },
  { "start": 415, "end": 415, "entry": { "verb": "run" } },
  { "start": 416, "end": 416, "entry": { "verb": "rush" } },
  { "start": 417, "end": 417, "entry": { "verb": "salute" } },
  { "start": 418, "end": 418, "entry": { "verb": "scamper" } },
  { "start": 419, "end": 419, "entry": { "verb": "scare" } },
  { "start": 420, "end": 420, "entry": { "verb": "scavenge" } },
  { "start": 421, "end": 421, "entry": { "verb": "scream" } },
  { "start": 422, "end": 422, "entry": { "verb": "scrounge" } },
  { "start": 423, "end": 423, "entry": { "verb": "search" } },
  { "start": 424, "end": 424, "entry": { "verb": "secure" } },
  { "start": 425, "end": 425, "entry": { "verb": "seduce" } },
  { "start": 426, "end": 426, "entry": { "verb": "segregate" } },
  { "start": 427, "end": 427, "entry": { "verb": "seize" } },
  { "start": 428, "end": 428, "entry": { "verb": "sell" } },
  { "start": 429, "end": 429, "entry": { "verb": "ship" } },
  { "start": 430, "end": 430, "entry": { "verb": "shout" } },
  { "start": 431, "end": 431, "entry": { "verb": "shove" } },
  { "start": 432, "end": 432, "entry": { "verb": "show" } },
  { "start": 433, "end": 433, "entry": { "verb": "silence" } },
  { "start": 434, "end": 434, "entry": { "verb": "sing" } },
  { "start": 435, "end": 435, "entry": { "verb": "slaughter" } },
  { "start": 436, "end": 436, "entry": { "verb": "sleep" } },
  { "start": 437, "end": 437, "entry": { "verb": "smash" } },
  { "start": 438, "end": 438, "entry": { "verb": "sob" } },
  { "start": 439, "end": 439, "entry": { "verb": "solicit" } },
  { "start": 440, "end": 440, "entry": { "verb": "speak" } },
  { "start": 441, "end": 441, "entry": { "verb": "spill" } },
  { "start": 442, "end": 442, "entry": { "verb": "spit" } },
  { "start": 443, "end": 443, "entry": { "verb": "spy" } },
  { "start": 444, "end": 444, "entry": { "verb": "stab" } },
  { "start": 445, "end": 445, "entry": { "verb": "stage" } },
  { "start": 446, "end": 446, "entry": { "verb": "startle" } },
  { "start": 447, "end": 447, "entry": { "verb": "steal" } },
  { "start": 448, "end": 448, "entry": { "verb": "stop" } },
  { "start": 449, "end": 449, "entry": { "verb": "strangle" } },
  { "start": 450, "end": 450, "entry": { "verb": "strike" } },
  { "start": 451, "end": 451, "entry": { "verb": "subdue" } },
  { "start": 452, "end": 452, "entry": { "verb": "suffer" } },
  { "start": 453, "end": 453, "entry": { "verb": "summon" } },
  { "start": 454, "end": 454, "entry": { "verb": "surround" } },
  { "start": 455, "end": 455, "entry": { "verb": "suspect" } },
  { "start": 456, "end": 456, "entry": { "verb": "take" } },
  { "start": 457, "end": 457, "entry": { "verb": "taunt" } },
  { "start": 458, "end": 458, "entry": { "verb": "tease" } },
  { "start": 459, "end": 459, "entry": { "verb": "tempt" } },
  { "start": 460, "end": 460, "entry": { "verb": "terrify" } },
  { "start": 461, "end": 461, "entry": { "verb": "terrorize" } },
  { "start": 462, "end": 462, "entry": { "verb": "thank" } },
  { "start": 463, "end": 463, "entry": { "verb": "threaten" } },
  { "start": 464, "end": 464, "entry": { "verb": "throw" } },
  { "start": 465, "end": 465, "entry": { "verb": "torment" } },
  { "start": 466, "end": 466, "entry": { "verb": "torture" } },
  { "start": 467, "end": 467, "entry": { "verb": "trade" } },
  { "start": 468, "end": 468, "entry": { "verb": "transform" } },
  { "start": 469, "end": 469, "entry": { "verb": "translate" } },
  { "start": 470, "end": 470, "entry": { "verb": "transport" } },
  { "start": 471, "end": 471, "entry": { "verb": "trap" } },
  { "start": 472, "end": 472, "entry": { "verb": "travel" } },
  { "start": 473, "end": 473, "entry": { "verb": "tug" } },
  { "start": 474, "end": 474, "entry": { "verb": "unite" } },
  { "start": 475, "end": 475, "entry": { "verb": "unleash" } },
  { "start": 476, "end": 476, "entry": { "verb": "unload" } },
  { "start": 477, "end": 477, "entry": { "verb": "unveil" } },
  { "start": 478, "end": 478, "entry": { "verb": "vandalize" } },
  { "start": 479, "end": 479, "entry": { "verb": "vanish" } },
  { "start": 480, "end": 480, "entry": { "verb": "victimize" } },
  { "start": 481, "end": 481, "entry": { "verb": "violate" } },
  { "start": 482, "end": 482, "entry": { "verb": "volunteer" } },
  { "start": 483, "end": 483, "entry": { "verb": "wait" } },
  { "start": 484, "end": 484, "entry": { "verb": "warn" } },
  { "start": 485, "end": 485, "entry": { "verb": "wave" } },
  { "start": 486, "end": 486, "entry": { "verb": "weep" } },
  { "start": 487, "end": 487, "entry": { "verb": "welcome" } },
  { "start": 488, "end": 488, "entry": { "verb": "whittle" } },
  { "start": 489, "end": 489, "entry": { "verb": "wield" } },
  { "start": 490, "end": 490, "entry": { "verb": "win" } },
  { "start": 491, "end": 491, "entry": { "verb": "wink" } },
  { "start": 492, "end": 492, "entry": { "verb": "witness" } },
  { "start": 493, "end": 493, "entry": { "verb": "worship" } },
  { "start": 494, "end": 494, "entry": { "verb": "wound" } },
  { "start": 495, "end": 495, "entry": { "verb": "wreck" } },
  { "start": 496, "end": 496, "entry": { "verb": "wrestle" } },
  { "start": 497, "end": 497, "entry": { "verb": "write" } },
  { "start": 498, "end": 498, "entry": { "verb": "yell" } },
  { "start": 499, "end": 499, "entry": { "verb": "yield" } },
];
