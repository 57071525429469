module Main exposing (Flags, Model, Msg, Page, main)

import Browser exposing (Document)
import Browser.Navigation as Nav
import Game.GeneratorData as GeneratorData
import Json.Decode as Decode exposing (Decoder, Value)
import Page.GeneratorCollection as Generators
import Page.HexCrawl as HexCrawl
import Page.Index
import Page.Oracle as Oracle
import Page.QuickChat as QuickChat
import Page.Writer as Writer
import Partial.InitFailed
import Partial.Shared
import Url exposing (Url)
import Url.Parser as Parser exposing (Parser, s)


main : Program Value Model Msg
main =
    Browser.application
        { init = init
        , view = view
        , update = update
        , subscriptions = subscriptions
        , onUrlChange = UrlChanged
        , onUrlRequest = LinkClicked
        }


type alias Model =
    { key : Nav.Key
    , page : Page
    , generatorData : GeneratorData.Data
    }


type Page
    = NotFound
    | InitFailure String
    | Index
    | Oracle Oracle.Model
    | GeneratorCollection Generators.Model
    | Writer Writer.Model
    | HexCrawl HexCrawl.Model
    | QuickChat QuickChat.Model


type alias Flags =
    { generatorData : GeneratorData.Data
    }


flagDecoder : Decoder Flags
flagDecoder =
    Decode.map Flags
        (Decode.field "generatorData"
            GeneratorData.dataDecoder
        )


init : Value -> Url -> Nav.Key -> ( Model, Cmd Msg )
init flags url key =
    let
        decodeResult =
            Decode.decodeValue flagDecoder flags
    in
    case decodeResult of
        Ok parsed ->
            stepUrl url <|
                { key = key
                , page = NotFound
                , generatorData = parsed.generatorData
                }

        Err decodeError ->
            ( { key = key
              , page = InitFailure <| Decode.errorToString decodeError
              , generatorData = GeneratorData.empty
              }
            , Cmd.none
            )


type Msg
    = LinkClicked Browser.UrlRequest
    | UrlChanged Url
    | OracleMsg Oracle.Msg
    | GeneratorCollectionMsg Generators.Msg
    | WriterMsg Writer.Msg
    | HexCrawlMsg HexCrawl.Msg
    | QuickChatMsg QuickChat.Msg


update : Msg -> Model -> ( Model, Cmd Msg )
update rootMsg model =
    case rootMsg of
        LinkClicked urlRequest ->
            case urlRequest of
                Browser.Internal url ->
                    ( model
                    , Nav.pushUrl model.key (Url.toString url)
                    )

                Browser.External href ->
                    ( model
                    , Nav.load href
                    )

        UrlChanged url ->
            stepUrl url model

        OracleMsg msg ->
            case model.page of
                Oracle oracle ->
                    stepOracle model (Oracle.update msg oracle)

                _ ->
                    ( model, Cmd.none )

        GeneratorCollectionMsg msg ->
            case model.page of
                GeneratorCollection gen ->
                    stepGenerators model (Generators.update msg gen)

                _ ->
                    ( model, Cmd.none )

        WriterMsg msg ->
            case model.page of
                Writer writer ->
                    stepWriter model (Writer.update msg writer)

                _ ->
                    ( model, Cmd.none )

        HexCrawlMsg msg ->
            case model.page of
                HexCrawl hexCrawl ->
                    stepHexCrawl model (HexCrawl.update msg hexCrawl)

                _ ->
                    ( model, Cmd.none )

        QuickChatMsg msg ->
            case model.page of
                QuickChat quickChat ->
                    stepQuickChat model (QuickChat.update msg quickChat)

                _ ->
                    ( model, Cmd.none )


stepIndex : Model -> ( Model, Cmd Msg )
stepIndex model =
    ( { model | page = Index }
    , Cmd.none
    )


stepOracle : Model -> ( Oracle.Model, Cmd Oracle.Msg ) -> ( Model, Cmd Msg )
stepOracle model ( oracle, cmds ) =
    let
        newModel =
            { model | page = Oracle oracle }
    in
    ( newModel
    , Cmd.map OracleMsg cmds
    )


stepGenerators : Model -> ( Generators.Model, Cmd Generators.Msg ) -> ( Model, Cmd Msg )
stepGenerators model ( gen, cmds ) =
    let
        newModel =
            { model | page = GeneratorCollection gen }
    in
    ( newModel
    , Cmd.map GeneratorCollectionMsg cmds
    )


stepWriter : Model -> ( Writer.Model, Cmd Writer.Msg ) -> ( Model, Cmd Msg )
stepWriter model ( writer, cmds ) =
    let
        newModel =
            { model
                | page = Writer writer
            }
    in
    ( newModel
    , Cmd.map WriterMsg cmds
    )


stepHexCrawl : Model -> ( HexCrawl.Model, Cmd HexCrawl.Msg ) -> ( Model, Cmd Msg )
stepHexCrawl model ( hexCrawl, cmds ) =
    ( { model | page = HexCrawl hexCrawl }
    , Cmd.map HexCrawlMsg cmds
    )


stepQuickChat : Model -> ( QuickChat.Model, Cmd QuickChat.Msg ) -> ( Model, Cmd Msg )
stepQuickChat model ( quickChat, cmds ) =
    ( { model | page = QuickChat quickChat }
    , Cmd.map QuickChatMsg cmds
    )


stepUrl : Url -> Model -> ( Model, Cmd Msg )
stepUrl url model =
    let
        parser : Parser (( Model, Cmd Msg ) -> c) c
        parser =
            Parser.oneOf
                [ Parser.top
                    |> Parser.map (stepIndex model)
                , s "oracle"
                    |> Parser.map
                        (stepOracle model ( Oracle.init, Cmd.none ))
                , s "generator"
                    |> Parser.map
                        (stepGenerators model ( Generators.init model.generatorData, Cmd.none ))
                , s "writer"
                    |> Parser.map
                        (stepWriter model ( Writer.init, Cmd.none ))
                , s "hexcrawl"
                    |> Parser.map
                        (stepHexCrawl model ( HexCrawl.init, Cmd.none ))
                , s "quick-chat"
                    |> Parser.map
                        (stepQuickChat model ( QuickChat.init, Cmd.none ))
                ]
    in
    case Parser.parse parser url of
        Just route ->
            route

        Nothing ->
            ( { model | page = NotFound }
            , Cmd.none
            )


view : Model -> Document Msg
view model =
    case model.page of
        NotFound ->
            { title = Partial.Shared.pageTitle "Not found"
            , body =
                [ Partial.Shared.backToTop
                ]
            }

        InitFailure error ->
            { title = Partial.Shared.pageTitle "Error!"
            , body =
                [ Partial.InitFailed.view error
                ]
            }

        Index ->
            Page.Index.view

        Oracle orcl ->
            Partial.Shared.skeleton OracleMsg (Oracle.view orcl)

        GeneratorCollection gen ->
            Partial.Shared.skeleton GeneratorCollectionMsg (Generators.view gen)

        Writer writer ->
            Partial.Shared.skeleton WriterMsg (Writer.view writer)

        HexCrawl hexCrawl ->
            Partial.Shared.skeleton HexCrawlMsg (HexCrawl.view hexCrawl)

        QuickChat quickChat ->
            Partial.Shared.skeleton QuickChatMsg (QuickChat.view quickChat)


subscriptions : Model -> Sub Msg
subscriptions _ =
    Sub.none
