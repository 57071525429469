export const itemRelicName = [{
  "start": 1,
  "end": 2,
  "entry": {
    "term1": "Cursed",
    "term2": "Sceptre of",
    "term3": "The Crimson",
    "term4": "Goddess",
  },
}, {
  "start": 3,
  "end": 4,
  "entry": {
    "term1": "Blessed",
    "term2": "Bowl of",
    "term3": "Desire",
    "term4": "Lightning",
  },
}, {
  "start": 5,
  "end": 6,
  "entry": {
    "term1": "Emerald",
    "term2": "Scroll of",
    "term3": "Enchanting",
    "term4": "Fire",
  },
}, {
  "start": 7,
  "end": 8,
  "entry": {
    "term1": "Illuminating",
    "term2": "Mirror of",
    "term3": "Merciful",
    "term4": "Fate",
  },
}, {
  "start": 9,
  "end": 10,
  "entry": {
    "term1": "Merciless",
    "term2": "Sword of",
    "term3": "Wrathful",
    "term4": "Rage",
  },
}, {
  "start": 11,
  "end": 12,
  "entry": {
    "term1": "All-seeing",
    "term2": "Stone of",
    "term3": "Pale",
    "term4": "Dawn",
  },
}, {
  "start": 13,
  "end": 14,
  "entry": {
    "term1": "Sheltering",
    "term2": "Casket of",
    "term3": "Endless",
    "term4": "Knowledge",
  },
}, {
  "start": 15,
  "end": 16,
  "entry": {
    "term1": "Dreaded",
    "term2": "Bell of",
    "term3": "Elemental",
    "term4": "Waters",
  },
}, {
  "start": 17,
  "end": 18,
  "entry": {
    "term1": "Immovable",
    "term2": "Chalice of",
    "term3": "Planar",
    "term4": "Music",
  },
}, {
  "start": 19,
  "end": 20,
  "entry": {
    "term1": "Mysterious",
    "term2": "Robe of",
    "term3": "Mighty",
    "term4": "Power",
  },
}, {
  "start": 21,
  "end": 22,
  "entry": {
    "term1": "Ever-changing",
    "term2": "Pipes of",
    "term3": "Joyous",
    "term4": "Peace",
  },
}, {
  "start": 23,
  "end": 24,
  "entry": {
    "term1": "Gilded",
    "term2": "Lamp of",
    "term3": "Exquisite",
    "term4": "Turmoil",
  },
}, {
  "start": 25,
  "end": 26,
  "entry": {
    "term1": "Many-coloured",
    "term2": "Idol of",
    "term3": "Cursed",
    "term4": "Illusion",
  },
}, {
  "start": 27,
  "end": 28,
  "entry": {
    "term1": "Heroic",
    "term2": "Tome of",
    "term3": "Opulent",
    "term4": "Morphing",
  },
}, {
  "start": 29,
  "end": 30,
  "entry": {
    "term1": "Wondrous",
    "term2": "Oil of",
    "term3": "Dimensional",
    "term4": "Secrets",
  },
}, {
  "start": 31,
  "end": 32,
  "entry": {
    "term1": "Boundless",
    "term2": "Drum of",
    "term3": "Temporal",
    "term4": "Navigation",
  },
}, {
  "start": 33,
  "end": 34,
  "entry": {
    "term1": "Depraved",
    "term2": "Chimes of",
    "term3": "Corrupt",
    "term4": "Fire",
  },
}, {
  "start": 35,
  "end": 36,
  "entry": {
    "term1": "Captivating",
    "term2": "Brazier of",
    "term3": "Omniscient",
    "term4": "Air",
  },
}, {
  "start": 37,
  "end": 38,
  "entry": {
    "term1": "Unusual",
    "term2": "Candelabra of",
    "term3": "Freezing",
    "term4": "Demon",
  },
}, {
  "start": 39,
  "end": 40,
  "entry": {
    "term1": "Dark",
    "term2": "Lute of",
    "term3": "Burning",
    "term4": "Frost",
  },
}, {
  "start": 41,
  "end": 42,
  "entry": {
    "term1": "Mystical",
    "term2": "Crown of",
    "term3": "Invisible",
    "term4": "Prophecy",
  },
}, {
  "start": 43,
  "end": 44,
  "entry": {
    "term1": "Eldritch",
    "term2": "Bottle of",
    "term3": "Distant",
    "term4": "Binding",
  },
}, {
  "start": 45,
  "end": 46,
  "entry": {
    "term1": "Elegant",
    "term2": "Rod of",
    "term3": "Planar",
    "term4": "Death",
  },
}, {
  "start": 47,
  "end": 48,
  "entry": {
    "term1": "Scintillating",
    "term2": "Prism of",
    "term3": "Unrelenting",
    "term4": "Laughter",
  },
}, {
  "start": 49,
  "end": 50,
  "entry": {
    "term1": "Stupendous",
    "term2": "Cauldron of",
    "term3": "Dark",
    "term4": "Purity",
  },
}, {
  "start": 51,
  "end": 52,
  "entry": {
    "term1": "Strange",
    "term2": "Crucible of",
    "term3": "Illuminating",
    "term4": "Alchemy",
  },
}, {
  "start": 53,
  "end": 54,
  "entry": {
    "term1": "Extravagant",
    "term2": "Decanter of",
    "term3": "Forbidden",
    "term4": "Insults",
  },
}, {
  "start": 55,
  "end": 56,
  "entry": {
    "term1": "Macabre",
    "term2": "Quill of",
    "term3": "Temporary",
    "term4": "Poison",
  },
}, {
  "start": 57,
  "end": 58,
  "entry": {
    "term1": "Imperial",
    "term2": "Horn of",
    "term3": "Decaying",
    "term4": "Healing",
  },
}, {
  "start": 59,
  "end": 60,
  "entry": {
    "term1": "Bizarre",
    "term2": "Globe of",
    "term3": "Crippling",
    "term4": "Heroism",
  },
}, {
  "start": 61,
  "end": 62,
  "entry": {
    "term1": "Jewelled",
    "term2": "Lens of",
    "term3": "Deafening",
    "term4": "Strength",
  },
}, {
  "start": 63,
  "end": 64,
  "entry": {
    "term1": "Superior",
    "term2": "Coffer of",
    "term3": "Blinding",
    "term4": "Form",
  },
}, {
  "start": 65,
  "end": 66,
  "entry": {
    "term1": "Animated",
    "term2": "Vase of",
    "term3": "Enlightening",
    "term4": "Mind-reading",
  },
}, {
  "start": 67,
  "end": 68,
  "entry": {
    "term1": "Chromatic",
    "term2": "Belt of",
    "term3": "Bolstering",
    "term4": "Longevity",
  },
}, {
  "start": 69,
  "end": 70,
  "entry": {
    "term1": "Illusory",
    "term2": "Cup of",
    "term3": "Benevolent",
    "term4": "Influence",
  },
}, {
  "start": 71,
  "end": 72,
  "entry": {
    "term1": "Angelic",
    "term2": "Shield of",
    "term3": "Glowing",
    "term4": "Evasion",
  },
}, {
  "start": 73,
  "end": 74,
  "entry": {
    "term1": "Metaphysical",
    "term2": "Armour of",
    "term3": "Resilient",
    "term4": "Protection",
  },
}, {
  "start": 75,
  "end": 76,
  "entry": {
    "term1": "Robust",
    "term2": "Elixir of",
    "term3": "Curious",
    "term4": "Resistance",
  },
}, {
  "start": 77,
  "end": 78,
  "entry": {
    "term1": "Simple",
    "term2": "Ring of",
    "term3": "Awe-inspiring",
    "term4": "Vision",
  },
}, {
  "start": 79,
  "end": 80,
  "entry": {
    "term1": "Divine",
    "term2": "Staff of",
    "term3": "Will-crushing",
    "term4": "Regeneration",
  },
}, {
  "start": 81,
  "end": 82,
  "entry": {
    "term1": "Ancient",
    "term2": "Wand of",
    "term3": "Telepathic",
    "term4": "Command",
  },
}, {
  "start": 83,
  "end": 84,
  "entry": {
    "term1": "Elven",
    "term2": "Arrow of",
    "term3": "Repelling",
    "term4": "Turning",
  },
}, {
  "start": 85,
  "end": 86,
  "entry": {
    "term1": "Dwarvish",
    "term2": "Bow of",
    "term3": "Absorbing",
    "term4": "Absorption",
  },
}, {
  "start": 87,
  "end": 88,
  "entry": {
    "term1": "Orcish",
    "term2": "Cloak of",
    "term3": "Unbreakable",
    "term4": "Adornment",
  },
}, {
  "start": 89,
  "end": 90,
  "entry": {
    "term1": "Dragonborn",
    "term2": "Helm of",
    "term3": "Dispelling",
    "term4": "Melody",
  },
}, {
  "start": 91,
  "end": 92,
  "entry": {
    "term1": "Tiefling",
    "term2": "Key of",
    "term3": "Frightening",
    "term4": "Pyrotechnics",
  },
}, {
  "start": 93,
  "end": 94,
  "entry": {
    "term1": "Drow",
    "term2": "Gem",
    "term3": "Burning",
    "term4": "Detection",
  },
}, {
  "start": 95,
  "end": 96,
  "entry": {
    "term1": "Shapeshifting",
    "term2": "Cube of",
    "term3": "Mischievous",
    "term4": "Paralysis",
  },
}, {
  "start": 97,
  "end": 98,
  "entry": {
    "term1": "Regenerating",
    "term2": "Talisman of",
    "term3": "Reflective",
    "term4": "Disruption",
  },
}, {
  "start": 99,
  "end": 100,
  "entry": {
    "term1": "Exquisite",
    "term2": "Device of",
    "term3": "Hypnotic",
    "term4": "Wizardry",
  },
}];
