import { Elm } from "./Main.elm";
import { urbanEvent, wildernessEvent } from "./data/event.js";
import { itemRelicName } from "./data/itemRelicName.js";
import { quest, questSource } from "./data/quest.js";
import { verb } from "./data/verb.js";

Elm.Main.init({
  node: document.getElementById("elm"),
  flags: {
    generatorData: {
      itemRelicName,
      urbanEvent,
      wildernessEvent,
      quest,
      questSource,
      verb,
    },
  },
});
